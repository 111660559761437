import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { dateFormatter } from '../../utils/dateFormatter.util';
import { scrollToTop } from '../../utils/scroll.util';
import {Hero} from '../../Components/Hero/Hero';
import {ContentBlock} from '../../Components/ContentBlock/ContentBlock';
import BackgroundImage from '../../images/empty-office.jpg';
import './Job.scss';
import styled from 'styled-components';
import {ApplicationModal} from 'src/Components/ApplicationModal/ApplicationModal';
import {colors} from 'src/utils/colors';
import {Button} from 'src/Components/atoms/Button';
import {SectionContainer} from 'src/Components/atoms/SectionContainer';
import {breakpoints} from 'src/utils/breakpoints';
import {H2, H3Styles} from 'src/Components/atoms/Text';

export const Job = (props) => {

    const JOB_QUERY = gql`
        {
            jobPostCollection {
                items {
                    title
                    date
                    location
                    slug
                    body {
                        json
                    }
                }
            }
        }
    `
    const [modal, setModal] = useState(false);
    const { data } = useQuery(JOB_QUERY);

    const closeModal = () => {
        const newState = !modal;
        setModal(newState);
    }

    const createMarkup = (richText) => {
        return {__html: documentToHtmlString(richText)};
    }

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        data ?
        (
        data.jobPostCollection.items.map((job, index) => {
            return (
                job.slug === props.match.params.slug && (
                        <div key={index}>
                            <Hero title={job.title} background={BackgroundImage} />
                            <SectionContainer>
                                <JobDetailsContainer>
                                    <Heading>{job.title}</Heading>
                                        <JobBody dangerouslySetInnerHTML={createMarkup(job.body.json)} />
                                    <Button onClick={() => setModal(true)}>Apply Now</Button>
                                </JobDetailsContainer>
                            </SectionContainer>
                            { modal ? <ApplicationModal data={job} onClick={closeModal}/> : null}
                        </div>
                    )
            )
        })
        )
        : null
    )
};

const JobDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 128px;
    padding-bottom: 96px;
    .jobHeaders {
        margin-bottom: 2rem;
        .datePosted {
            color: $darkGrey;
            font-style: italic;
            margin-top: .5rem;
            span {
                color: ${colors.primaryColor};
                font-style: normal;
            }
        }
    }
`;

const Heading = styled(H2)`
    margin-bottom: 32px;
`;

const JobBody = styled.div`
    h3 {
        margin: 48px 0 16px 0;
        ${H3Styles}
    }
    ul {
        li {
            margin-left: 24px;
            margin-bottom: 8px;
            @media only screen and (min-width: ${breakpoints.tablet}) {
                list-style: outside;
            }
        }
    }
    > p {
        margin-bottom: 32px;
    }
`;
