export const validateFirstName = (firstName: string): boolean => {
    if (firstName.length < 2) {
        return true;
    }
    return false;
};

export const validateLastName = (lastname: string): boolean => {
    if (lastname.length < 2) {
        return true;
    }
    return false;
};

export const validateEmail = (email: string): boolean => {
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regEx.test(email.toLowerCase())) {
        return false;
    };
    return true;
};

export const validateMessage = (message: string): boolean => {
    if (message.length < 2) {
        return true;
    }
    return false;
};

export const validatePhone = (phoneNumber: string): boolean => {
    const regEx = /^[0-9]*$/;
    if (phoneNumber.length >= 8 && regEx.test(phoneNumber.toLowerCase())) {
        return false;
    }
    return true;
};

export const validateFile = (file: string): boolean => {
    if(file.length < 2) {
        return true;
    };
    return false;
}