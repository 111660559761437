import {ComponentPropsWithoutRef} from 'react';
import styled from 'styled-components';

interface SectionContainerProps extends ComponentPropsWithoutRef<'section'> {
    innerStyles?: React.CSSProperties;
}

export const SectionContainer = ({children, innerStyles, ...props}: SectionContainerProps) => (
    <SectionOuter {...props}>
        <SectionInner style={innerStyles}>
            {children}
        </SectionInner>
    </SectionOuter>
);

const SectionOuter = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 16px;
`;

const SectionInner = styled.div`
    display: flex;
    width: 100%;
    max-width: 1400px;
`;

