import {breakpoints} from 'src/utils/breakpoints';
import {colors} from 'src/utils/colors';
import styled, {css} from 'styled-components';

export const H2 = styled.h2`
    font-size: 1.75rem;
    color: ${colors.baseColor};
    line-height: 1.75rem;
    @media only screen and (min-width: ${breakpoints.tablet}) {
        font-size: 2.5rem;
        line-height: 3rem;
    }
`;

export const H3Styles = css`
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.75rem;
    color: ${colors.baseColor};
    letter-spacing: -1px;
    @media only screen and (min-width: ${breakpoints.smartphone}) {
        font-size: 2rem;
        line-height: 2rem;
    }
`;

export const H3 = styled.h3`
    ${H3Styles}
`;