import { useEffect, useState } from 'react';
import { useQuery, gql, QueryResult } from '@apollo/client';
import { scrollToTop } from '../../utils/scroll.util';
import {Hero} from '../../Components/Hero/Hero';
import BackgroundImage from '../../images/empty-office.jpg';
import {Job, JobItem} from '../../Components/Jobs/JobListContainer/JobItem';
import {H2} from 'src/Components/atoms/Text';
import {SectionContainer} from 'src/Components/atoms/SectionContainer';
import styled from 'styled-components';
import {breakpoints} from 'src/utils/breakpoints';

const JOBPOST_QUERY = gql`
    {
        jobPostCollection {
            items {
                title
                date
                description
                location
                slug
                body {
                    json
                }
            }
        }
    }
`;

interface JobPostResponse {
    jobPostCollection: {
        items: Job[];
    }
}

const Vacancies = () => {
    const {data}: QueryResult<JobPostResponse> = useQuery(JOBPOST_QUERY);
    const [sortedJobs, setSortedJobs] = useState<Job[] | null>(null);

    useEffect(() => {
        scrollToTop();
    }, [])

    useEffect(() => {
        if (data?.jobPostCollection.items) {
            setSortedJobs([...data.jobPostCollection.items].sort(compare));
        }
    }, [data])

    return (
        <>
            <Hero 
                title="Vacancies"
                copy="Take a look at some of the roles we currently have below"
                background={BackgroundImage}
            />
            <SectionContainer innerStyles={{flexDirection: 'column', marginTop: '128px'}}>
                <H2>Open roles</H2>
                <ContentContainer>
                    {sortedJobs && sortedJobs.map((job: Job, index: number) => <JobItem key={index} job={job} />)}
                </ContentContainer>
            </SectionContainer>
        </>
    )
}

const compare = (a: Job, b: Job) => {
    if ( a.title < b.title ){
      return -1;
    }
    if ( a.title > b.title ){
      return 1;
    }
    return 0;
  }

const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    width: 100%;
    padding: 32px 0 194px 0;
    text-align: left;
    @media only screen and (min-width: ${breakpoints.tablet}) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export default Vacancies;