interface Breakpoints {
    smartphone: string;
    tablet: string;
    desktop: string;
}

export const breakpoints: Breakpoints = {
    smartphone: '500px',
    tablet: '768px',
    desktop: '1024px',
};