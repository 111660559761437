import {useEffect} from 'react';
import styled from 'styled-components';
import {Hero} from '../../Components/Hero/Hero';
import {ContentBlock} from '../../Components/ContentBlock/ContentBlock';
import {TeamMembers} from '../../Components/TeamMembers/TeamMembers';
import BackgroundImage from '../../images/empty-office.jpg';
import {scrollToTop} from '../../utils/scroll.util';
import {breakpoints} from '../../utils/breakpoints';
import {teamMembers} from './teamConfig';

const MeetTheTeam = () => {
    useEffect(() => {
        document.title = 'Magnitude Consulting :: Meet the team';
        scrollToTop();
    }, []);

    return (
        <>
            <Hero 
                title="Meet the team"
                copy="Work with our fast-growing specialist executive head hunting team and start creating futures!"
                background={BackgroundImage}
            />
            <ContentBlock>
                <TeamSectionContainer>
                    <h3>Magnitude Members</h3>
                    <TeamMembers teamMembers={teamMembers} />
                    <p>Become a part of our driven, dynamic and diverse team, working with some exciting clients across the Globe. Our areas of focus are Sales, Marketing, Customer Success, Professional Services and Technical roles across Software, ICT and Digital Transformation.</p>
                    <p>We offer roles across a broad range of industries ranging from Financial Services, Telecommunications, Utilities, Manufacturing, Supply Chain, Government and much more.</p>
                    <p> We cover multiple roles across Sales, Marketing, Executive Hire and Technical in all industries. Our positioning in the market and professional reputation will push you towards dynamic brands, disruptive start-ups and leading digital transformation businesses across the globe.</p>
                    <p>At Magnitude Consulting, we drive our values creating a diverse environment where diversity is embraced and achievements are recognised. Magnitude Consulting will support your career goals, whatever they are. We will do out best to create a great team culture.</p>
                </TeamSectionContainer>
            </ContentBlock>
        </>
    )
}

export default MeetTheTeam;

const TeamSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 64px;
    h3 {
        margin: 0 auto;
        @media only screen and (min-width: ${breakpoints.tablet}) {
            margin: 0;
        }
    }
`;