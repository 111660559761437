import React, { useEffect, useState } from 'react';
import { sendEmail } from '../../utils/api';
import { validateFirstName, validateLastName, validateEmail, validateMessage } from '../../utils/contactUtils';
import LoadingIcon from '../Icons/LoadingIcon/LoadingIcon';
import './Contact.scss';

const Contact = (props) => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [industry, setIndustry] = useState('');
    const [message, setMessage] = useState('');

    const [firstnameError, setFirstnameError] = useState(null);
    const [lastnameError, setLastnameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [messageError, setMessageError] = useState(null);

    const [isMounted, setIsMounted] = useState(false);
    const [isUnMounted, setIsUnmounted] = useState(false);
    const [requestPending, setRequestPending] = useState(false);

    const [successMessage, setSuccessMessage] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isUnMounted) {
            return;
        }

        if (!isMounted || firstnameError || lastnameError || emailError || messageError) {
            return;
        }

        setRequestPending(true);

        const postData = async () => {

            const response = await sendEmail({firstname, lastname, email, location, industry, message});

            if (response) {
                resetForm();
                setRequestPending(false);
                setSuccessMessage(true);
                return;
            }

            console.error('Email was not sent');
            setRequestPending(false);

        };

        postData();

        return () => {
            setIsUnmounted(true);
        }

    }, [firstnameError, lastnameError, emailError, messageError]);

    const submitRequest = async (evt) => {
        evt.preventDefault();
        setFirstnameError(validateFirstName(firstname));
        setLastnameError(validateLastName(lastname));
        setEmailError(validateEmail(email));
        setMessageError(validateMessage(message));
    };

    const resetForm = () => {
        setFirstname('');
        setLastname('');
        setEmail('');
        setLocation('');
        setIndustry('');
        setMessage('');
    };

    return (
        <section id="contact-section" className="contactContainer gutter">
            <div className="formContainer">
                <form onSubmit={submitRequest}>
                    <div className="formRow">
                        <div className="inputGroup">
                            <label className="required" htmlFor="firstname">First name</label>
                            <input type="text" name="firstname" value={firstname} onChange={e => setFirstname(e.target.value)}></input>
                            {firstnameError && <p className="errorMessage">Please enter a valid first name</p>}
                        </div>
                        <div className="inputGroup">
                            <label className="required" htmlFor="lastname">Last name</label>
                            <input type="text" name="lastname" value={lastname} onChange={e => setLastname(e.target.value)}></input>
                            {lastnameError && <p className="errorMessage">Please enter a valid last name</p>}
                        </div>
                    </div>
                    <div className="inputGroup">
                        <label className="required" htmlFor="email">Email</label>
                        <input type="text" name="email" value={email} onChange={e => setEmail(e.target.value)}></input>
                        {emailError && <p className="errorMessage">Please enter a valid email</p>}
                    </div>
                    <div className="inputGroup">
                        <label htmlFor="location">Location</label>
                        <input type="text" name="location" value={location} onChange={e => setLocation(e.target.value)}></input>
                    </div>
                    <div className="inputGroup">
                        <label htmlFor="industry">Industry/Vertical</label>
                        <input type="text" name="industry" value={industry} onChange={e => setIndustry(e.target.value)}></input>
                    </div>
                    <div className="inputGroup">
                        <label className="required" htmlFor="message">Message</label>
                        <textarea name="message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                        {messageError && <p className="errorMessage">Please enter a valid message</p>}
                    </div>
                    <button type="submit">Send { requestPending ? <LoadingIcon /> : null }</button>
                    { successMessage ? <p className="successMessage">Message successfully sent!</p> : null }
                </form>
            </div>
            <div className="copyContainer">
                <h2>{props.title}</h2>
                <p>{props.copy}</p>
                <ul>
                    <li>{props.tel}</li>
                    <li>{props.email}</li>
                    <li>Address: Brunel House, 2 Fitzalan Rd, Cardiff, CF240HA</li>
                </ul>
            </div>
        </section>
    )
}

export default Contact;