import {ComponentPropsWithoutRef} from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../utils/breakpoints';
import { colors } from '../../utils/colors';

export const ContentBlock = ({children}: ComponentPropsWithoutRef<'section'>) => {
    return (
        <ContentBlockContainer className="gutter">
            {children}
        </ContentBlockContainer>
    )
}

const ContentBlockContainer = styled.section`
    padding-top: 4rem;
    padding-bottom: 6rem;
    background: $white;
    text-align: left;
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding-bottom: 8rem;
    }
    h3 {
        display: inline-block;
        padding-top: 3rem;
        padding-bottom: .5rem;
        margin-bottom: 1.5rem;
        border-bottom: 2px solid $grey;
        font-size: 1.75rem;
        font-weight: 500;
        color: ${colors.baseColor};
        letter-spacing: -1px;
        &:nth-of-type(1) {
            padding-top: 0;
        }
        @media only screen and (min-width: ${breakpoints.smartphone}) {
            font-size: 2rem;
            text-align: left;
        }
    }
    p {
        padding-bottom: 3rem;
        font-size: 1rem;
        color: ${colors.darkestGrey};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @media only screen and (min-width: ${breakpoints.desktop}) {
            font-size: 18px;
        }
        &:last-of-type {
            padding-bottom: 0;
        }
    }
`;