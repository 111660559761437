import "./Styles/global.scss";
import {BrowserRouter} from "react-router-dom";
import {Layout} from "./Components/Layout/Layout";
import {ApolloProvider, ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';

const App = () => {
    const httpLink = createHttpLink({
        uri: 'https://graphql.contentful.com/content/v1/spaces/avmqq6t4bs5r?access_token=QcUDdGUlWdTn8-aZHT7awqgK3nEr22-2L_rwGDa2eTg'
      });
      
      const client = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache()
      });

    return (
        <BrowserRouter>
            <ApolloProvider client={client}>
                <div className="App">
                    <Layout />
                </div>
            </ApolloProvider>
        </BrowserRouter>
    );
}

export default App;
