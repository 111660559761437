import styled from 'styled-components';
import {breakpoints} from '../../utils/breakpoints';
import {colors} from '../../utils/colors';
import {TeamMember} from '../../Pages/MeetTheTeam/teamConfig';

interface TeamMembersProps {
    teamMembers: TeamMember[]
}

export const TeamMembers = ({teamMembers}: TeamMembersProps) => (
    <MemberProfilesContainer>
        {teamMembers.map(({name, image, role, linkedinUrl}) => (
            <MemberContainer key={name}>
                <img src={image} alt={name} />
                <p className="name">{name}</p>
                <p className="role">{role}</p>
                {linkedinUrl && <a href={linkedinUrl} target="_blank" rel="noreferrer">
                    <LinkedInIcon className="fab fa-linkedin-in"></LinkedInIcon>
                </a>}
            </MemberContainer>
        ))}
    </MemberProfilesContainer>
);

const MemberProfilesContainer = styled.section`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 64px;
    width: 100%;
    margin: 64px 0;
    @media only screen and (min-width: ${breakpoints.tablet}) {
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 96px;
    }
    @media only screen and (min-width: ${breakpoints.desktop}) {
        gap: 96px;
    }
`;

const MemberContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    max-width: 300px;
    margin: 0 auto;
    img {
        width: 100%;
        margin-bottom: 24px;
        border-radius: 50%;
    }
    p {
        padding: 0;
        text-align: center;
        &.name {
            font-weight: 600;
        }
        &.role {
            font-style: italic;
            color: ${colors.primaryColor};
        }
    }
`;

const LinkedInIcon = styled.i`
    font-size: 1.25rem;
    color: ${colors.baseColor};
    &:hover {
        transform: scale(1.1);
    }
`;