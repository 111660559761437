import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Homepage from '../../Pages/Home';
import About from '../../Pages/About';
import MeetTheTeam from '../../Pages/MeetTheTeam';
import Vacancies from '../../Pages/Vacancies';
import {Job} from '../../Pages/Job';

const Routes = () => {
    
    return (
        <Switch>
            <Route path="/" exact component={Homepage} />
            <Route path="/about" exact component={About} />
            <Route path="/meet-the-team" exact component={MeetTheTeam} />
            <Route path="/vacancies" exact render={() => <Vacancies />} />
            <Route path="/:slug" exact component={Job} />
        </Switch>
    )
}

export default Routes;