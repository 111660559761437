
import { Link } from 'react-router-dom';
import {Button} from 'src/Components/atoms/Button';
import styled from 'styled-components';
import {H3} from 'src/Components/atoms/Text';
import {colors} from 'src/utils/colors';
import {breakpoints} from 'src/utils/breakpoints';

export interface Job {
    date: String;
    description?: string;
    location?: string;
    slug: string;
    title: string;
    body: string;
}

interface JobItemProps {
    job: Job
}

export const JobItem = ({job}: JobItemProps) => {
    const {description, location, title} = job;
    return (
        <JobContainer>
            <H3>{title}</H3>
            {location && <Location>{`Location: `}<span>{location}</span></Location>}
            {description && <Description>{description}</Description>}
            <Button as={Link} to={`/${job.slug}`}>Read more</Button>
        </JobContainer>
    )
};

const JobContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 32px;
    background: ${colors.grey};
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding: 48px 32px;
    }
    a {
        align-self: flex-start;
        margin-top: 1rem;
    }
`;

const Location = styled.p`
    margin-top: 8px;
    color: ${colors.primaryColor};
    span {
        color: ${colors.darkGrey};
        font-style: italic;
    }
`;

const Description = styled.p`
    margin-block: 24px;
    font-weight: 300;
`;
