import {useEffect} from 'react';
import {scrollToTop} from '../../utils/scroll.util';
import {Header} from '../Header/Header';
import {Footer} from '../Footer/Footer';
import Routes from '../Routes/Routes';
import styled from 'styled-components';

export const Layout = () => {

    useEffect(() => {
        scrollToTop();
    }, [])

    return (
        <LayoutContainer>
            <Header />
                <main style={{width: '100%'}}>
                    <Routes />
                </main>
            <Footer />
        </LayoutContainer>
    )
}

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
`;