import React, {useEffect} from 'react';
import {Hero} from '../../Components/Hero/Hero';
import BackgroundImage from '../../images/empty-office.jpg';
import {scrollToTop} from '../../utils/scroll.util';
import {SectionContainer} from 'src/Components/atoms/SectionContainer';
import styled from 'styled-components';
import {H2, H3} from 'src/Components/atoms/Text';
import {breakpoints} from 'src/utils/breakpoints';

const About = () => {
    useEffect(() => {
        document.title = 'Magnitude Consulting :: About';
        scrollToTop();
    }, []);

    return (
        <>
            <Hero 
                title="About Us"
                copy="Driving business growth and value proposition Globally across IT Sales, Marketing, Technical and Executive Hire."
                background={BackgroundImage}
            />
            <SectionContainer>
                <ContentContainer>
                    <H2>Who we are</H2>
                    <p>Magnitude Consulting focuses on executive talent search and recruitment services for clients across ICT, Digital Transformation and Software. We provide permanent and contract recruitment for a wide variety of industries, attracting the best talent globally.</p>
                    <p>The Magnitude Consulting team have a history of working across new technology including Digital Transformation, Cloud Computing, ICT and innervational change transformation, recruiting proven professionals across varied levels of expertise. Our Industry track record will ensure you receive the ‘best of best’ services across suitability and value add. Our team will work with you to help you onboard people for their capability across innovation, strategy, and delivery.</p>
                    <p>Our strength is understanding both our clients and candidates' key goals, culture, and skill set. We will be focused on developing a long-term approach, driving into your business core, understanding the end goal while tailoring our service to add the highest of value.</p>
                    <p>Magnitude Consulting's engagement with your business is to create an unforgettable recruitment process ranging from service, search, and value allowing for a professional and long-term relationship. Our recruitment approach will drive diversity, culture, and corporate professionalism.</p>
                </ContentContainer>
            </SectionContainer>
        </>
    )
}

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
        padding: 64px 0 80px 0;
    text-align: left;
    @media only screen and (min-width: ${breakpoints.tablet}) {
        padding: 128px 0 194px 0;
    }
`;

export default About;