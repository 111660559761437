import { useRef, useState, useEffect } from 'react';
import { sendApplication } from '../../utils/api';
import './ApplicationModal.scss';
import LoadingIcon from '../Icons/LoadingIcon/LoadingIcon';
import {validateEmail, validateFile, validateFirstName, validatePhone} from 'src/utils/contactUtils';

interface ApplicationModalProps {
    onClick: () => void;
    data: any;
}

export const ApplicationModal = ({onClick, data}: ApplicationModalProps) => {
    const hiddenFileInput = useRef(null);

    // Fields
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [file, setFile] = useState('');

    // Error messages
    const [nameError, setNameError] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<boolean>(false);
    const [phoneError, setPhoneError] = useState<boolean>(false);
    const [fileError, setFileError] = useState<boolean>(false);

    const [isMounted, setIsMounted] = useState(false);
    const [isUnMounted, setIsUnmounted] = useState(false);
    const [requestPending, setRequestPending] = useState(false);


    const [successMessage, setSuccessMessage] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isUnMounted) {
            return;
        }

        if (!isMounted || nameError || emailError || phoneError || fileError) {
            return;
        }

        setRequestPending(true);

        const postData = async () => {

            const response = await sendApplication({name, email, phone, file, data});

            if (response) {
                resetForm();
                setSuccessMessage(true);
                setRequestPending(false);
                return;
            }
            setRequestPending(false);
            console.error('Email was not sent');
        };

        postData();

        return () => {
            setIsUnmounted(true);
        }

    }, [nameError, emailError, phoneError, fileError]);

    const submitRequest = (e: any) => {
        e.preventDefault();
        setNameError(validateFirstName(name));
        setEmailError(validateEmail(email));
        setPhoneError(validatePhone(phone));
        setFileError(validateFile(file));
    }

    const resetForm = () => {
        setName('');
        setEmail('');
        setPhone('');
        setFile('');
    };
    
    return (
        <section className="applicationContainer">
            <form className="applicationForm" onSubmit={submitRequest}>
                <div className="close" onClick={onClick}>
                    <span></span>
                    <span></span>
                </div>
                <p className="roleTitle">{data.title}</p>
                <p className="location">{data.location}</p>
                <label htmlFor="fullname">Full Name</label>
                <input type="text" name="fullname" value={name} onChange={(e) => setName(e.target.value)}></input>
                {nameError && <p className="errorMessage">Please enter a valid full name</p>}
                <label htmlFor="email">Email</label>
                <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)}></input>
                {emailError && <p className="errorMessage">Please enter a valid email</p>}
                <label htmlFor="phone">Phone Number</label>
                <input type="text" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)}></input>
                {phoneError && <p className="errorMessage">Please enter a valid phone number</p>}
                <label htmlFor="file">Resume</label>
                <div className="fileInputContainer">
                    <input  
                        className="fileInput"
                        type="file"
                        accept='.doc,.docx,.pdf'
                        ref={hiddenFileInput}
                        onChange={(e) => setFile(e.target.value)}
                    />
                    <button className="fileBtn">Choose file</button>
                    {fileError && <p className="errorMessage">Please upload a valid file</p>}
                </div>
                <button className="applyBtn" type="submit">Apply { requestPending ? <LoadingIcon /> : null }</button>
                { successMessage ? <p className="successMessage">Message successfully sent!</p> : null }
            </form>
        </section>
    )
};