import React, {useEffect} from 'react';
import {Hero} from '../../Components/Hero/Hero';
import HalfBlock from '../../Components/HalfBlock/HalfBlock';
import Contact from '../../Components/Contact/Contact';
import officeImage from '../../images/office.jpg';
import writtingImage from '../../images/person-writing.jpg';
import laptopImage from '../../images/laptop.jpg'

const Homepage = () => {

    useEffect(() => {
        document.title = 'Magnitude Consulting :: Home';
    }, []);

    return (
        <>
            <Hero 
                title="Welcome"
                copy="Magnitude Consulting is an IT Consultancy focused on sourcing exceptional talent across permanent recruitment throughout APAC, US &amp; EMEA. Magnitude Consulting focuses on understanding both client and candidate’s needs."
                background={writtingImage}
            />
            <HalfBlock 
                title="Consulting"
                copy="We focus on executive talent search and recruitment services for clients across ICT, Digital Transformation and Software. We provide permanent and contract recruitment for a wide variety of industries, attracting the best talent globally."
                background={officeImage}
                copyFirst={true}
                learnMoreLink='./about'
            />
            <HalfBlock 
                title="Tailored Results"
                copy="All our recruitment offering is tailored to meet both candidate and client needs. As a result, we’ve earned a trusted, reliable and proven reputation. We consistently and efficiently supply high quality candidates to our clients and match our candidates to positions which we know will fulfil their career development."
                background={laptopImage}
                copyFirst={false}
                learnMoreLink='./about'
            />
            <Contact 
                title="Let's talk"
                copy="For a quick enquiry fill out the contact form or contact us directly using the contact details below."
                // tel="Telephone: +61************"
                email="Email: tom@magnitude-consulting.com"
            />
        </>
    )
}

export default Homepage;