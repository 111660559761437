// const BASE_URL = 'https://magnitude-consulting.herokuapp.com';
const BASE_URL = 'http://localhost:8080';

interface SendEmail {
    firstname: string;
    lastname: string;
    email: string;
    location: string;
    industry: string;
    message: string;
}

export const sendEmail = async (formData: SendEmail) => {
    const response = await fetch(`${BASE_URL}/api/contact`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(formData)
    });

    const resStatus = response.status;
    if (resStatus === 200) {
        return true;
    } else if (resStatus === 500) {
        return false;
    }
    return;
};

export const sendApplication = async (formData: any) => {
    const response = await fetch(`${BASE_URL}/api/apply`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(formData)
    });

    const resStatus = await response.status;
    if (resStatus === 200) {
        return true;
    } else if (resStatus === 500) {
        return false;
    }
    return;
};