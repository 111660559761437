import styled, {keyframes} from 'styled-components';
import {ComponentPropsWithoutRef} from 'react';
import {breakpoints} from 'src/utils/breakpoints';
import {colors} from 'src/utils/colors';

interface HeroProps extends ComponentPropsWithoutRef<'section'> {
    background: string;
    copy: string;
    title: string;
}

export const Hero = ({background, copy, title, ...props}: HeroProps) => (
    <HeroContainer {...props} className="gutter" backgroundImage={background}>
        <H1>{title}</H1>
        <Bar />
        {copy ? <Text>{copy}</Text> : null}
    </HeroContainer>
);

const AnimateTextDown = keyframes`
    from { 
        opacity: 0;
        transform: translateY(-80px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const AnimateTextUp = keyframes`
    from { 
        opacity: 0;
        transform: translateY(80px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const BarAni = keyframes`
    from { 
        width: 0;
    }
    to {
        width: 200px;
    }
`;

const barAniMobile = keyframes`
    from { 
        width: 0;
    }
    to {
        width: 100px;
    }
`;

interface HeroContainerProps {
    backgroundImage: string;
}

const HeroContainer = styled.section<HeroContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;
    text-align: center;
    background-image: linear-gradient(to top, rgba(0, 0, 0, .4), rgba(0, 0, 0, .6)), url(${props => props.backgroundImage});
    background-size: cover;
    background-position: center top;
    color: #FFF;
`;

const H1 = styled.h1`
    opacity: 0;
    line-height: 2.5rem;
    animation-name: ${AnimateTextDown};
    animation-duration: 1s;
    animation-delay: .5s;
    animation-fill-mode: forwards;
    @media only screen and (min-width: ${breakpoints.tablet}) {
        line-height: 4rem;
    }
`;

const Bar = styled.span `
    height: 4px;
    width: 0;
    margin: 1rem 0;
    background: ${colors.primaryColor};
    border-radius: 5px;
    animation-name: ${barAniMobile};
    animation-duration: .5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    @media only screen and (min-width: ${breakpoints.tablet}) {
        height: 5px;
        animation-name: ${BarAni};
        animation-duration: .5s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
    }
`;

const Text = styled.p `
    font-size: 1rem;
    font-weight: 600;
    opacity: 0;
    animation-name: ${AnimateTextUp};
    animation-duration: 1s;
    animation-delay: .5s;
    animation-fill-mode: forwards;
    @media only screen and (min-width: ${breakpoints.tablet}) {
        max-width: 800px;
        font-size: 1.4rem;
    }
`;