import React from 'react';

const LoadingIcon = () => {
    return (
        <div className="loadingContainer">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default LoadingIcon;