import React from 'react';
import {Link} from 'react-router-dom';
import './HalfBlock.scss';

const LearnMore = (props) => {
    return (
        props.learnMoreLink ? (
            <div className="imageOverlay">
                <Link to={props.learnMoreLink}><button className="learnMoreBtn">Learn more</button></Link>
            </div>
        ) : null
    )
}

const HalfBlock = (props) => {
    const {copyFirst, title, copy, background, learnMoreLink} = props;

    return (
        <section className={`halfblockContainer ${copyFirst === false ? 'reverse' : null} `}>
            <div className="copyContainer">
                <h2>{title}</h2>
                <p>{copy}</p>
            </div>
            <div className="imageContainer" style={{ backgroundImage: `url(${background})` }}>
                <LearnMore learnMoreLink={learnMoreLink}/>
            </div>
        </section>
    )
}

export default HalfBlock;