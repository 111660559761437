import tdProfile from '../../images/Tom-Davis.jpg';
import jsProfile from '../../images/Jess-Shellard.jpg';
import rmProfile from '../../images/Ruth-Manager.jpg';
import ilProfile from '../../images/Izzy-Lysaght.jpg';
import hbProfile from '../../images/Harrison-Boyce.jpg';
import ndprofile from '../../images/Natasha-Davies.jpg';

export interface TeamMember {
    name: string;
    image: string;
    role: string;
    linkedinUrl?: string;
}

export const teamMembers: TeamMember[] = [
    {
        name: 'Tom Davies',
        image: tdProfile,
        role: 'Director',
        linkedinUrl: 'https://www.linkedin.com/in/tom-davies-51a32a105/',
    }, {
        name: 'Jess Shellard',
        image: jsProfile,
        role: 'Head of Account Management',
        linkedinUrl: 'https://www.linkedin.com/in/jessmagnitude/',
    }, {
        name: 'Izzy Lysaght',
        image: ilProfile,
        role: 'Senior Business Manager',
        linkedinUrl: 'https://www.linkedin.com/in/izzy-lysaght-7b0827144/',
    }, {
        name: 'Natasha Davies',
        image: ndprofile,
        role: 'Associate Account Manager',
        linkedinUrl: 'https://www.linkedin.com/in/natasha--davies/',
    }, {
        name: 'Harrison Boyce',
        image: hbProfile,
        role: 'Account Manager',
        linkedinUrl: 'https://www.linkedin.com/in/harrison-boyce-4501261b9/',
    }, {
        name: 'Ruth Manager',
        image: rmProfile,
        role: 'Senior Business Manager',
        linkedinUrl: 'https://www.linkedin.com/in/ruth-may-4803131bb/',
    },
];