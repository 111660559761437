import React from 'react';
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './MobileMenu.scss';

const MobileMenu = (props) => {
    return (
        <div className={`mobileMenuContainer ${props.showMenu ? "show" : ""}`}>
            <ul>
                <li>
                    <Link to="/about" onClick={() => props.closeMenu()}>About</Link>
                </li>
                <li>
                    <Link to="/meet-the-team" onClick={() => props.closeMenu()}>Meet the team</Link>
                </li>
                {/* <li>
                    <Link to="/whyus" onClick={() => props.closeMenu()}>Why Magnitude</Link>
                </li> */}
                <li>
                    <Link to="/vacancies" onClick={() => props.closeMenu()}>Vacancies</Link>
                </li>
                <li>
                    <HashLink to="/#contact-section" onClick={() => props.closeMenu()}>Contact Us</HashLink>
                </li>
            </ul>
        </div>
    )
}

export default MobileMenu;