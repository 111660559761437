import {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import MobileMenu from '../MobileMenu/MobileMenu';
import Logo from '../../images/logo-color.svg';
import LogoWhite from '../../images/logo-white.svg';
import './Header.scss';
import styled from 'styled-components';
import {breakpoints} from 'src/utils/breakpoints';
import {colors} from 'src/utils/colors';

export const Header = () => {
    const [transparency, setTransparency] = useState(true);
    const [mobileMenu, setMobileMenu] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', checkScrollPos);
    }, [])


    const checkScrollPos = () => {
        const scrollPos = window.scrollY;
        if(scrollPos < 100) {
            setTransparency(true);
        } else {
            setTransparency(false);
        }
    }

    const handleMobileMenu = (close: boolean) => {
        if(close) {
            setMobileMenu(false);
            return;
        }
        const mobileMenuShow = !mobileMenu;
        setMobileMenu(mobileMenuShow);
    }

    return(
        <>
            <HeaderContainer className={`headerContainer ${transparency ? 'transparent' : ''}`}>
                <HeaderInner>
                    <NavLink to="/">
                        <img src={mobileMenu || !transparency ? Logo : LogoWhite} alt="Logo" onClick={() => handleMobileMenu(true)}/>
                    </NavLink>
                    <ul>
                        <li>
                            <NavLink to="/about" activeClassName='isActive'>About</NavLink>
                        </li>
                        <li>
                            <NavLink to="/meet-the-team" activeClassName='isActive'>Meet the team</NavLink>
                        </li>
                        <li>
                            <NavLink to="/vacancies" activeClassName='isActive'>Vacancies</NavLink>
                        </li>
                        <li className="roundBtn">
                            <HashLink to="/#contact-section">Contact Us</HashLink>
                        </li>
                    </ul>
                    <div className={`hamburgerContainer ${mobileMenu || !transparency ? "blue" : ""} ${mobileMenu ? "open" : ""}`} onClick={() => handleMobileMenu(false)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </HeaderInner>
            </HeaderContainer>
            <MobileMenu 
                showMenu={mobileMenu}
                closeMenu={handleMobileMenu}
            />
        </>
    )
}

const HeaderContainer = styled.header`
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    padding: 12px 16px;
    background: ${colors.white};
    font-family: 'Open Sans', sans-serif;
    z-index: 2;
    transition: all .5s ease;
    @media only screen and (min-width: ${breakpoints.desktop}) {
        padding: 16px;
    }
`;

const HeaderInner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1400px;
`;

