import {colors} from 'src/utils/colors';
import styled from 'styled-components'

export const Button = styled.button`
    padding: 12px 24px;
    color: ${colors.white};
    background: ${colors.baseColor};
    border: none;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    transition: all .5s ease;
    &:hover {
        background: ${colors.primaryColor};
    }
`;