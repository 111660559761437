import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import {scrollToTop} from '../../utils/scroll.util';
import LogoWhite from '../../images/logo-white.svg';
import styled from 'styled-components';
import {colors} from 'src/utils/colors';
import {breakpoints} from 'src/utils/breakpoints';

export const Footer = () => {
    return (
        <FooterContainer className="gutter">
            <FooterTop>
                <FooterLinks />
                <div className="scrollBtn" onClick={scrollToTop}>
                    <span></span>
                    <span></span>
                </div>
            </FooterTop>
            <FooterBottom>
                <img src={LogoWhite} alt="magnitude logo"></img>
                <p>All content copyrighted Magnitude Consulting &copy; All rights reserved</p>
            </FooterBottom>
        </FooterContainer>
    )
};

const FooterLinks = () => (
    <ul>
        <li>
            <Link to="/">Home</Link>
        </li>
        <li>
            <Link to="/about">About</Link>
        </li>
        <li>
            <Link to="/meet-the-team">Meet the team</Link>
        </li>
        <li>
            <Link to="/vacancies">Vacancies</Link>
        </li>
        <li>
            <HashLink to="/#contact-section">Contact</HashLink>
        </li>
        <li>
            <a href="https://www.linkedin.com/company/magnitude-consultingltd/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
        </li>
    </ul>
);

const FooterContainer = styled.footer`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: auto;
    padding-top: 2rem;
    padding-bottom: 4rem;
    background: ${colors.baseColor};
    @media only screen and (min-width: ${breakpoints.smartphone}) {
        padding-top: 4rem;
    }
    &:before {
        content: '';
        position: absolute;
        top: -30px;
        left: 0;
        width: 0; 
        height: 0; 
        border-top: 30px solid transparent;
        border-right: 100vw solid ${colors.baseColor};
        @media only screen and (min-width: ${breakpoints.smartphone}) {
            top: -60px;
            border-top: 60px solid transparent;
        }
    }
`;

const FooterTop = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $white;
    ul {
        padding-bottom: 2rem;
        text-align: left;
        li {
            list-style: none;
            a {
                text-decoration: none;
                color: #E7E7E7;
                &:hover {
                    color: ${colors.white};
                }
            }
            i {
                margin-top: 1rem;
            }
        }
    }
    .scrollBtn {
        position: relative;
        width: 32px;
        height: 32px;
        background: ${colors.primaryColor};
        cursor: pointer;
        transition: all .2s ease;
        &:hover {
            transform: translateY(-4px);
            span {
                background: #FFF;
            }
        }
        span {
            position: absolute;
            top: 14px;
            width: 14px;
            height: 4px;
            background: $white;
            border-radius: 2px;
            transition: all .2s ease;
            background: ${colors.baseColor};
            &:nth-last-of-type(1) {
                left: 13px;
                transform: rotate(45deg);
            }
            &:nth-last-of-type(2) {
                left: 5px;
                transform: rotate(-45deg);
            }
        }
    }
`;

const FooterBottom = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    text-align: center;
    @media only screen and (min-width: ${breakpoints.desktop}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
    }
    img {
        width: 170px;
        @media only screen and (min-width: ${breakpoints.smartphone}) {
            width: 200px;
        }

    }
    p {
        margin-top: 1rem;
        font-size: .75rem;
        color: ${colors.white};
        @media only screen and (min-width: ${breakpoints.desktop}) {
            margin-top: 0;
        }
    }
`;